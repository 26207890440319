<template>
  <v-autocomplete
    :value="value"
    :search-input.sync="search"
    :error-messages="errorMessages"
    :loading="loading"
    :disabled="disabled"
    :hint="autoCompleteHint"
    :label="label"
    :item-value="itemValue"
    :item-text="itemText"
    :items="autocompleteItems"
    :prepend-icon="prependedIcon"
    :append-icon="appendedIcon"
    :hide-no-data="hideNoData"
    :no-filter="noFilter"
    error-count="5"
    hide-selected
    multiple
    chips
    deletable-chips
    clearable
    return-object
    @blur="$emit('blur', $event)"
    @click:append="$emit('click:append')"
    @click:clear="clearInput"
    @input="onInput"
    @keyup="onKeyUp"
  >
    <template v-if="useSelectionSlot" v-slot:selection="{ item }">
      <slot name="selection" :item="item"></slot>
    </template>
    <template v-if="useItemSlot" v-slot:item="{ item }">
      <slot name="item" :item="item"></slot>
    </template>
    <template v-if="useAppend" v-slot:append>
      <slot name="append"></slot>
    </template>
  </v-autocomplete>
</template>

<script>
export default {
  name: 'BaseMultipleAutocomplete',

  props: {
    // Models (e.g. user) array.
    value: {
      type: Array,
      default: () => [],
    },

    // Function called with the value that user has typed into the input.
    // Typically an API call
    searchFunction: {
      type: Function,
      required: true,
    },

    label: {
      type: String,
      required: true,
    },

    itemValue: {
      type: String,
      default: 'id',
    },

    itemText: {
      type: String,
      default: 'name',
    },

    errorMessages: {
      type: Array,
      default: () => [],
    },

    noFilter: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    appendedIcon: {
      type: String,
      default: '',
    },

    useSelectionSlot: {
      type: Boolean,
      default: false,
    },

    useItemSlot: {
      type: Boolean,
      default: false,
    },

    useAppend: {
      type: Boolean,
      default: false,
    },

    prependedIcon: {
      type: String,
      default: 'search',
    },

    // How long will the component wait for an additional keypress after the last one
    // before calling searchFunction
    debounceTime: {
      type: Number,
      default: 600,
    },
  },

  data() {
    return {
      items: [],
      loading: false,
      search: '',
      timerId: null,
      hideNoData: true,
    };
  },

  computed: {
    autoCompleteHint() {
      if (this.value.length) {
        return '';
      }
      return !this.search || this.search.length < 3
        ? this.$t('general.enter_three_or_more_symbols')
        : '';
    },

    autocompleteItems() {
      const itemsMap = {};

      const setItems = (items) => {
        for (let i = 0; i < items.length; i++) {
          const key = items[i][this.itemValue];
          if (!itemsMap[key]) {
            itemsMap[key] = items[i];
          }
        }
      };

      setItems(this.value);
      setItems(this.items);

      return Object.values(itemsMap);
    },
  },

  methods: {
    clearInput() {
      this.hideNoData = true;
      this.items = [];
    },

    async getItems(query) {
      this.loading = true;
      try {
        const res = await this.searchFunction(query);
        this.items = res.data.data ? res.data.data : res.data;
        this.hideNoData = false;
      } catch (e) {
        this.items = [];
        throw e;
      }
      this.loading = false;
    },

    onKeyUp(event) {
      if (event.key === 'Enter') {
        return;
      }
      clearTimeout(this.timerId);
      if (!this.search) {
        this.clearInput();
        return;
      }
      if (this.search.length < 3) {
        return;
      }
      this.timerId = setTimeout(() => {
        this.getItems(this.search);
      }, this.debounceTime);
    },

    onInput(value) {
      this.hideNoData = true;
      this.search = '';
      this.$emit('input', value);
    },
  },
};
</script>
