<template>
  <v-card>
    <v-card-title>
      {{ formTitle }}
    </v-card-title>

    <v-card-text>
      <v-row dense>
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="item.first_name"
            :error-messages="errors['first_name']"
            :label="formMixin_getRequiredFieldLabel($t('general.personal_info.first_name'))"
            @input="formMixin_clearErrors('first_name')"
          />
        </v-col>

        <v-col cols="12" sm="6">
          <v-text-field
            v-model="item.last_name"
            :error-messages="errors['last_name']"
            :label="$t('general.personal_info.last_name')"
            @input="formMixin_clearErrors('last_name')"
          />
        </v-col>

        <v-col cols="12" sm="6">
          <v-text-field
            v-model="item.email"
            :error-messages="errors['email']"
            :label="formMixin_getRequiredFieldLabel($t('general.common_contact_info.email'))"
            @input="formMixin_clearErrors('email')"
          />
        </v-col>

        <v-col cols="12" sm="6">
          <v-text-field
            v-model="item.phone"
            :error-messages="errors['personal_data.phone']"
            :label="$t('general.common_contact_info.phone')"
            @input="formMixin_clearErrors('phone')"
          />
        </v-col>

        <v-col v-if="!$isClient()" cols="12">
          <ContactMultipleAutocomplete
            v-model="item.clients"
            :error-messages="clientsErrorsArray"
            @input="clearClientsErrors"
          />
        </v-col>

        <v-col cols="12" sm="6">
          <v-select
            v-model="item.role"
            :items="userRoles"
            :error-messages="errors['role']"
            :label="$t('general.role')"
            :disabled="!$isAdmin()"
            @change="formMixin_clearErrors('role')"
          />
        </v-col>

        <v-col cols="12" sm="6">
          <v-checkbox
            v-model="item.is_active"
            :disabled="$isCurrentUser(item.id)"
            :error-messages="errors['is_active']"
            :label="$t('general.actions.active')"
            @change="formMixin_clearErrors('is_active')"
          />
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-actions>
      <span v-if="!item.id" class="text-subtitle-2 ml-3">
        * {{ $t('general.must_be_filled') }}
      </span>

      <v-spacer />

      <v-btn text @click="$emit('cancel')">
        {{ $t('general.controls.cancel') }}
      </v-btn>

      <v-btn
        :disabled="disabled"
        :loading="disabled"
        color="primary"
        text
        @click="$emit('save', item)"
      >
        {{ $t('general.controls.save') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import formMixin from '@/mixins/form-mixin';
import clone from 'just-clone';
import ContactMultipleAutocomplete from '@/components/autocompletes/ContactMultipleAutocomplete';

export default {
  name: 'UserForm',

  components: { ContactMultipleAutocomplete },

  mixins: [formMixin],

  props: {
    user: {
      type: Object,
      required: true,
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      item: {},
    };
  },

  created() {
    const item = clone(this.user);
    if (item.id) {
      item.first_name = item.personal_data.first_name;
      item.last_name = item.personal_data.last_name;
      item.email = item.personal_data.email;
      item.phone = item.personal_data.phone;
      item.is_active = item.status === 'active';
    }
    this.item = item;
  },

  computed: {
    ...mapGetters('users', ['userRoles']),

    clientsErrorsArray() {
      const errors = [];
      Object.keys(this.errors).forEach((error) => {
        if (error.includes('client_ids')) {
          errors.push(this.errors[error][0]);
        }
      });
      return errors;
    },

    formTitle() {
      return this.$t(`users.modal_titles.${this.item.id ? 'edit_user' : 'create_user'}`);
    },
  },

  methods: {
    ...mapMutations('users', ['CLEAR_USER_VALIDATION_ERRORS']),

    clearClientsErrors() {
      Object.keys(this.errors).forEach((error) => {
        if (error.includes('client_ids')) {
          this.CLEAR_USER_VALIDATION_ERRORS(error);
        }
      });
    },
  },
};
</script>
