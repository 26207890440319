<template>
  <v-dialog
    :value="true"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    transition="slide-y-reverse-transition"
    max-width="800"
    scrollable
    persistent
  >
    <UserForm
      :disabled="$store.getters.loading[`put:api/client-portal-user/${editedUser.id}`]"
      :errors="userValidationErrors"
      :user="editedUser"
      @clear:errors="CLEAR_USER_VALIDATION_ERRORS"
      @cancel="goBack"
      @save="onSave"
    />
  </v-dialog>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import store from '@/store';
import UserForm from '@/components/forms/UserForm';

export default {
  name: 'EditUser',

  components: { UserForm },

  computed: {
    ...mapState('users', ['editedUser', 'userValidationErrors', 'userFilterParams']),
  },

  beforeRouteEnter(to, from, next) {
    store
      .dispatch('users/editUser', to.params.id)
      .then(() => {
        next();
      })
      .catch(() => {
        next({ name: 'users', query: store.state.users.userFilterParams });
      });
  },

  methods: {
    ...mapActions('users', ['updateUser']),
    ...mapMutations('users', ['CLEAR_USER_VALIDATION_ERRORS']),

    goBack() {
      this.$router.push({ name: 'users', query: this.userFilterParams });
    },

    onSave(user) {
      this.updateUser(user).then(() => {
        this.goBack();
      });
    },
  },
};
</script>
