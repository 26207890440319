<template>
  <BaseMultipleAutocomplete
    :value="value"
    :search-function="searchFunction"
    :label="label"
    :error-messages="errorMessages"
    item-text="name"
    use-item-slot
    use-selection-slot
    no-filter
    @input="$emit('input', $event)"
  >
    <template v-slot:item="{ item }">
      <v-list-item-content>
        <v-list-item-title>
          <span v-if="item.name">{{ item.name }}</span>
          <span v-else>{{ item.full_name }}</span>
        </v-list-item-title>
        <v-list-item-subtitle
          v-if="!item.is_supplier && !item.is_lead && !item.is_erp_company"
          class="text-body-2"
          style="font-size: 12px !important"
        >
          {{ $t('general.client') }}
        </v-list-item-subtitle>
        <v-list-item-subtitle
          v-if="item.is_supplier"
          class="text-body-2"
          style="font-size: 12px !important"
        >
          {{ $t('general.supplier') }}
        </v-list-item-subtitle>
        <v-list-item-subtitle
          v-if="item.is_lead"
          class="text-body-2"
          style="font-size: 12px !important"
        >
          {{ $t('general.lead') }}
        </v-list-item-subtitle>
        <v-list-item-subtitle
          v-if="item.is_erp_company"
          class="text-body-2"
          style="font-size: 12px !important"
        >
          {{ $t('general.erp_company') }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </template>
    <template v-slot:selection="{ item }">
      <span v-if="item.name">
        <v-chip>{{ item.name }}</v-chip>
      </span>
      <span v-else>
        <v-chip>{{ item.full_name }}</v-chip>
      </span>
    </template>
  </BaseMultipleAutocomplete>
</template>

<script>
import contactService from '@/api/contact-service';
import i18n from '@/i18n/i18n-config';
import BaseMultipleAutocomplete from '@/components/base/BaseMultipleAutocomplete';

export default {
  name: 'ContactMultipleAutocomplete',

  components: { BaseMultipleAutocomplete },

  props: {
    value: {
      type: Array,
      default: null,
    },
    errorMessages: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: () => i18n.t('general.common_contact_info.contacts'),
    },
  },

  computed: {
    searchFunction() {
      return this.searchContacts;
    },
  },

  methods: {
    searchContacts: contactService.search,
  },
};
</script>

<style scoped></style>
